import React from "react"
import Layout from '../components/layout';
import "../styles/scss/common/about.scss"
import Helmet from "react-helmet"

const ContactPage = () => {
	const seoData = {
		'title' : 'Contact Us, About Us page', 
		'hide_month' : true,
		'description' : 'Contact Us, About Us page', 
		'canonicalUrl' : '/contact-us/', 
		'robots' : 'index,follow'
		};
	
	const orgSchema = { 
		"@context": "https://schema.org",
		"@type": "Organization",
		"name": "HappyCredit",
		"legalName" : "CYBERGENES INNOVATIONS PRIVATE LIMITED",
		"url": "https://happycredit.in/",
		"logo": "https://happycredit.in/static/images/hc-logo.svg",
		"foundingDate": "2019",
		"founders": {
			"@type": "Person",
			"name": "Jitendra Kumar"
		},
		"address": {
			"@type": "PostalAddress",
			"streetAddress": "#677, 1st Floor, Suite 973, 13th Cross Road, 27th Main Road, Sector - 1, HSR Layout",
			"addressLocality": "Bangalore",
			"addressRegion": "Karnataka",
			"postalCode": "560102",
			"addressCountry": "India"
		},
		"contactPoint": {
			"@type": "ContactPoint",
			"contactType": "customer service",
			"telephone": "+9195134 26304",
			"email": "info@happycredit.in",
			"areaServed": "IN"
		},
		"sameAs": [ 
			"https://www.facebook.com/HappyCreditApp",
			"https://www.instagram.com/happycreditapp/",
			"https://twitter.com/HappyCreditApp/",
			"https://www.linkedin.com/company/happycredit/"
		]
	};

	
	return(
	<>
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(orgSchema)}</script>
		</Helmet>
  <Layout page={seoData} >
    <div id="about-us" className="about-us">
		<div className="abt-banner">
		<h1 className="container">About Us </h1>
		</div>
		<div className="container">
		<p>HappyCredit is India’s first Credit and Debit cards based offers and rewards platform. Our mission is to ensure you never miss out on an offer that is already available exclusively for your debit and credit card. We imagine a world where every card swipe delivers a delightful and rewarding experience to our users.</p>
		<p>On the HappyCredit platform, users can </p>
			<ul>
				<li>Discover offers across all categories like Shopping, travel, food etc. for all your debit and credit cards.</li>
				<li>Get timely reminders for offers expiring soon on your cards and favourite stores. </li>
				<li>Get exclusive rewards every time you swipe your card. (App only feature).</li>
			</ul>
		</div>
	</div>	
    <div id="contact-us" className="about-us">
		<div className="abt-banner">
		<h1 className="container">Contact Us</h1>
		</div>
		<div className="container">
		<div className="g-map">
		<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.489548726508!2d77.6502504!3d12.9159225!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4aa45474a53b8493!2sHappyCredit%20(Cybergenes%20Innovations%20Pvt%20Ltd)%20-%20Get%20Best%20Credit%20%26%20Debit%20Card%20Offers!5e0!3m2!1sen!2sin!4v1576361418946!5m2!1sen!2sin" allowfullscreen=""></iframe>
		</div>
		<div className="address">
		<img src={'/images/location.svg'} alt="location" />
		<h2>Address:<br />HappyCredit,<br />(CyberGenes Innovations Private Limited),<br /> #677, 1st Floor, Suite 973, 13th Cross Road,<br /> 27th Main Road, Sector - 1, HSR Layout,<br /> Bengaluru, Karnataka - 560102, India.</h2>
		<h2>Phone: +91-95134 26304</h2>
		<h2>Email: info@happycredit.in</h2>
		</div>
		</div>
	</div>	
  </Layout>
  </>
)
}

export default ContactPage
